import { useRouter } from 'next/router';
import { saveEventV3 } from '../../utils/eventTracking';

function FooterTrustpilotBox() {
  const router = useRouter();

  return (
    <>
      {/* <!-- TrustBox widget - Mini --> */}
      <div
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="620954b38dcd7aa362d49d76"
        data-style-height="150px"
        data-style-width="100%"
        data-theme="dark"
      >
        <a
          href="https://uk.trustpilot.com/review/driffle.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            saveEventV3({
              category: 'trustpilot',
              action: 'click',
              label: 'trustpilot_footer',
              properties: 'open',
              from: router,
              value: [],
            });
          }}
        >
          Trustpilot
        </a>
      </div>
      {/* <!-- End TrustBox widget --> */}
    </>
  );
}

export default FooterTrustpilotBox;
